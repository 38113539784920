import utilsApi from '../assets/js/utils'

import ajax from '../assets/js/axios.config'

export default utilsApi.creatApi({
   getPageList:['post', '/payOrders/page'], 
   getOrder:['get', '/payOrders/get'], 
   delOrder:['post', '/payOrders/delete'], 

   stages:['get', '/contractOrders/stages'], 

})