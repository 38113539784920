<template>
    <div class="Full">
        <el-header>
            <div class="logo">代扣信息管理系统</div>
            <header-com></header-com>
        </el-header>
        <el-container>
            <el-aside width="200px" ref="asideBox" id="asideBox">
                <el-menu
                    @select="menuPush"
                    background-color="#333E52"
                    text-color="#fff"
                    active-text-color="#ffd04b"
                    style="border: 0"
                >
                    <template v-for="(menu, index) in menuList">
                        <el-menu-item :index="index.toString()" v-if="!menu.chaildren">
                            <i class="el-icon-menu"></i>
                            <span slot="title">{{ menu.name }}</span>
                        </el-menu-item>
                        <!-- <el-submenu
                            v-if="
                                $utils.storage.get('mobileNumber') == '15076117670' && menu.component == 'userConfigure'
                            "
                            index="1"
                        > -->
                        <el-submenu index="1" v-if="false">
                            <template slot="title">
                                <i class="el-icon-menu"></i>
                                <span slot="title">{{ menu.name }}</span>
                            </template>
                            <el-menu-item-group v-for="(item, i) in menu.chaildren" :key="i">
                                <el-menu-item :index="'u' + i">{{ item.name }}</el-menu-item>
                            </el-menu-item-group>
                        </el-submenu>
                    </template>
                </el-menu>
            </el-aside>

            <el-main id="mainBox" ref="mainBox">
                <!-- <div class="breadcrumbBox">
                    <BreadCrumb></BreadCrumb>
                </div> -->
                <div class="content-wrap" ref="contentwrap" id="contentwrap">
                    <router-view />
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { HeaderCom, BreadCrumb } from "./comm";
export default {
    name: "Full",
    data() {
        return {
            menuList: [
                {
                    name: "签约订单",
                    component: "contractOrder"
                },
                {
                    name: "支付订单",
                    component: "paymentOrder"
                },
                {
                    name: "历史订单",
                    component: "historyOrder"
                },
                {
                    name: "合同备案",
                    component: "contractBackups"
                },
                {
                    name: "线上协议(暂定)",
                    component: "onlineProtocol"
                },
                // {
                //     name: "用户管理",
                //     component: "userConfigure",
                //     chaildren: [
                //         {
                //             name: "商户管理",
                //             component: "businessConfigure"
                //         },
                //         {
                //             name: "账号管理",
                //             component: "accountConfigure"
                //         }
                //     ]
                // }
            ]
        };
    },
    created() {},
    mounted() {},
    methods: {
        menuPush(e) {
            if (e.indexOf("u") != -1) {
                this.$router.push({
                    name: this.menuList[5].chaildren[Number(e.split('u')[1])].component
                });
            }else {
                this.$router.push({
                    name: this.menuList[e].component
                });
            }
        }
    },
    components: {
        HeaderCom,
        BreadCrumb
    }
};
</script>

<style lang="scss" scoped>
.Full {
    .el-header {
        padding: 0 0;
        // background: rgba(0,0,0,0.8);
        background: #404e68;
        position: relative;
        .logo {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            position: absolute;
            top: 30%;
            left: 20px;
        }
    }
    .el-main {
        // background-color: #eaeef5;
        overflow: auto;
        padding: 0 20px;
    }
    .el-aside {
        background: #333e52;
        // min-height: 93vh;
        margin-bottom: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .el-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        flex-basis: auto;
        box-sizing: border-box;
        min-width: 0;
    }
}
</style>
