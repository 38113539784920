<template>
    <div class="breadCrumbBox">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(m, mi) in breadcrumbList" :key="mi" :to="{ path: m.path }">{{
                m.meta.title
            }}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
</template>

<script>
export default {
    name: "BreadCrumb",
    data() {
        return {
            breadcrumbList: []
        };
    },
    mounted() {
        this.breadcrumbList = this.$route.matched;
        let list=[]
        this.$route.matched.forEach((item,index)=>{
                if (item.name.indexOf('/')!=-1) {
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[0]
                        },
                        path:'/'+item.name.split('/')[1]
                    })
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[1]
                        }
                    })
                }else{
                    list.push(item)
                }
            })
            this.breadcrumbList =list
    },
    methods: {},
    computed: {
        getMatched() {

        }
    },
    watch: {
        $route(to, from) {
            // this.breadcrumbList = to.matched;
            let list=[]
            to.matched.forEach((item,index)=>{
                if (item.name.indexOf('/')!=-1) {
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[0]
                        },
                        path:'/'+item.name.split('/')[1]
                    })
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[1]
                        }
                    })
                }else{
                    list.push(item)
                }
            })
            this.breadcrumbList =list
           
        }
    },
    components: {}
};
</script>

<style lang="scss" scoped>
.breadCrumbBox {
    height: 48px;
    line-height: 48px;
    display: block;
    align-items: center;
    padding-top: 17px;
    box-sizing: border-box;
}
</style>