<template>
    <div class="Header">
       
        <div class="menuBox">
            <my-menu></my-menu>

            <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(m, mi) in breadcrumbList" :key="mi" :to="{ path: m.path }">{{
                m.meta.title
            }}</el-breadcrumb-item>
        </el-breadcrumb>
        </div>
        
        <user></user>
    </div>
</template>

<script>
import user from '../Header/user.vue';
import MyMenu from '../Header/menu.vue'
export default {
    name: "Header",
    data() {
        return {
            breadcrumbList: []
        };
    },
    mounted() {
        this.breadcrumbList = this.$route.matched;
        let list=[]
        this.$route.matched.forEach((item,index)=>{
                if (item.name.indexOf('/')!=-1) {
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[0]
                        },
                        path:'/'+item.name.split('/')[1]
                    })
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[1]
                        }
                    })
                }else{
                    list.push(item)
                }
            })
            this.breadcrumbList =list
    },
    methods: {},
    watch: {
        $route(to, from) {
            // this.breadcrumbList = to.matched;
            let list=[]
            to.matched.forEach((item,index)=>{
                if (item.name.indexOf('/')!=-1) {
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[0]
                        },
                        path:'/'+item.name.split('/')[1]
                    })
                    list.push({
                        meta:{
                            title:item.meta.title.split('/')[1]
                        }
                    })
                }else{
                    list.push(item)
                }
            })
            this.breadcrumbList =list
           
        }
    },
    components: {
        user,
        MyMenu
    },
};
</script>

<style lang="scss" scoped>
.Header {
    height: 60px;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 20px 0 28px;
    box-sizing: border-box;
    position: fixed;

    right: 0;
    left: 200px;
    z-index: 99;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    .menuBox {
        flex: 1;
    }
}
</style>