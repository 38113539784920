<template>
    <div id="GsServerView">
        <router-view />
        <div class="bah">
            <div class="bahBox">
                <span>@ 佰联卓嘉</span>
                <a href="https://beian.miit.gov.cn/#/Integrated/index" rel="noreferrer" target="_blank" class="a1">冀ICP备2024080935号</a>
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=13019902000974" rel="noreferrer" target="_blank" class="a2">
                    <img src="./assets/image/beian.png" alt="" >
                    冀公网安备13019902000974号
                </a>
            </div>
            
        </div>
    </div>
</template>
<script>
import router from "./router";
export default {
    name: "GsServerView",
    data (){
        return{
        }
    },
    mounted() {
      
    },
};
</script>
<style lang="scss">
a { text-decoration: none; color: inherit; }
#GsServerView{
    position: relative;
  
    .bah{
        position: absolute;
        bottom: 0;
        right: 30px;
        color: #999;
        font-size: 12px;
        .bahBox{
            display: flex;
            // flex-direction: column;
            gap: 5px;
            // span,.a1{
            //     margin-left: 16px;
            // }
            .a2{
                display: flex;
                align-items: center;
                img{
                    width: 16px;
                    height: 16px;
                    margin-right: 3px;
                    margin-bottom: 2px;
                }
            }
        }
        
    }
}
.homeBg {
    height: 100%;
}
.contentBlock {
    background: #ffffff;
    border-radius: 4px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.contentBlock:last-child {
    margin-bottom: 0;
}
.zhanweiBox {
    background: #fff;
    height: 80px;
}
.topBtnBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.determineBox {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.el-menu--popup {
    min-width: 144px !important;
    padding: 0 0 !important;
    margin-top: 0 !important;
}

.el-menu--popup .el-menu-item {
    width: 144px;
    height: 40px !important;
    background-color: #2e354d !important;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
}
.el-menu--popup .el-menu-item:hover {
    background-color: #404861 !important;
}
.el-menu--popup .el-menu-item.is-active {
    background-color: #404861 !important;
    color: #fff !important;
    &.clearBg {
        background: #2e354d !important;
    }
}


::-webkit-scrollbar-thumb {
    background-color: #d8d8d8;
}
::-webkit-scrollbar-track {
    border-radius: 2px;
    background: rgba(255, 255, 255, 0);
}

.listScreen {
    width: 10px;
    height: 10px;
    display: inline-block;
}


</style>
